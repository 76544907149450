import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import axios from "axios";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { Container, media } from "styled-bootstrap-grid";
import Layout from "../components/Layout";
import Filter from "../components/Filter";
import ProductTable from "../components/ProductTable";

const StyledHorizontalLine = styled.hr`
  height: 2px;
  border: 1px solid #bbc5cc;
  margin: 0;

  :first-child {
    display: ${(props) => (props.isSubGroup ? "block" : "none")};
  }
`;

const GroupHeading = styled.h2`
  font-size: 18px;
  font-weight: bold;
  margin: 20px 0;
`;

const HeadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ListContainer = styled(Container)`
  margin-top: 150px;

  ${media.lg`
    margin-top: 300px;
`}
`;

const ChildrenContainer = styled.div`
  margin: 0 20px;
`;

const ProductGroup = ({ products, groupTitle, filter, isSubGroup }) => {
  const [isOpened, setIsOpened] = useState();

  let params = new URL(window.location).searchParams;
  let token = params.get("path");
  let selectedCategory = _.split(token, ",");

  const isSelected = selectedCategory.includes(_.kebabCase(_.lowerCase(groupTitle)));

  useMemo(() => {
    setIsOpened(filter.length > 0 || isSelected);
  }, [filter, isSelected]);

  return (
    <>
      <StyledHorizontalLine isSubGroup={isSubGroup} />

      <HeadingContainer onClick={() => setIsOpened(!isOpened)} id={groupTitle}>
        <GroupHeading>{groupTitle}</GroupHeading>

        {isOpened ? (
          <FontAwesomeIcon icon={faChevronDown} size="lg" />
        ) : (
          <FontAwesomeIcon icon={faChevronLeft} size="lg" />
        )}
      </HeadingContainer>
      {isOpened && (
        <ChildrenContainer>
          {_.isArray(products[groupTitle]) ? (
            <ProductTable productsData={products[groupTitle]} />
          ) : (
            _.keys(products[groupTitle]).map((subKey, index) => (
              <ProductGroup
                products={products[groupTitle]}
                groupTitle={subKey}
                filter={filter}
                key={index}
                isSubGroup={true}
              />
            ))
          )}
        </ChildrenContainer>
      )}
    </>
  );
};

const filterProducts = (baseProducts, filterValue) => {
  let result = {};
  if (!_.isArray(baseProducts)) {
    _.keys(baseProducts).forEach((key) => {
      if (!_.isArray(baseProducts[key])) {
        result[key] = filterProducts(baseProducts[key], filterValue);
      } else {
        result[key] = baseProducts[key]
          .filter((product) => {
            return (
              product.name.toLowerCase().includes(filterValue.toLowerCase()) ||
              product.code.toLowerCase().includes(filterValue.toLowerCase()) ||
              product.description.toLowerCase().includes(filterValue.toLowerCase())
            );
          })
          .sort((a, b) => {
            if (a.popularity === b.popularity) {
              return a.name.localeCompare(b.name);
            }
            return a.popularity - b.popularity;
          });
      }
    });
  } else {
    result = baseProducts
      .filter(
        (product) =>
          product.name.toLowerCase().includes(filterValue.toLowerCase()) ||
          product.code.toLowerCase().includes(filterValue.toLowerCase()) ||
          product.description.toLowerCase().includes(filterValue.toLowerCase())
      )
      .sort((a, b) => {
        if (a.popularity === b.popularity) {
          return a.name.localeCompare(b.name);
        }
        return a.popularity - b.popularity;
      });
  }
  return result;
};

const Products = ({ jwtToken }) => {
  const [products, setProducts] = useState([]);
  const [baseProducts, setBaseProducts] = useState([]);
  const [productFilter, setProductFilter] = useState("");

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const queryResult = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/products`, {
          headers: {
            Authorization: jwtToken,
          },
        });
        const queryProducts = queryResult.data;
        setBaseProducts(queryProducts);
        setProducts(filterProducts(queryProducts, ""));
      } catch (error) {
        console.error(error);
      }
    };

    fetchProducts();
  }, [jwtToken]);

  const onChangeFilter = (e) => {
    e.preventDefault();
    const filterValue = e.target.value;
    setProductFilter(filterValue);
    setProducts(filterProducts(baseProducts, filterValue));
  };

  useEffect(() => {
    const firstGroup = _.findKey(
      products,
      (productGroup) =>
        productGroup.length > 0 ||
        (!_.isArray(productGroup) && _.findKey(productGroup, (subGroup) => subGroup.length > 0))
    );

    let firstSubGroup;

    if (!_.isArray(products[firstGroup])) {
      firstSubGroup = _.findKey(products[firstGroup], (subGroup) => subGroup.length > 0);
    }

    const scrollPoint =
      firstSubGroup !== undefined ? document.getElementById(firstSubGroup) : document.getElementById(firstGroup);

    if (scrollPoint && productFilter.length > 2) {
      const top = scrollPoint.getBoundingClientRect().top - (window.innerWidth > 992 ? 300 : 150);

      window.scrollTo({ top: top, behavior: "smooth" });
    }
  }, [products, productFilter]);

  return (
    <Layout>
      <Filter label="Products" onChangeFilter={(e) => onChangeFilter(e)} />
      <ListContainer>
        {_.keys(products).map((key, index) => (
          <ProductGroup products={products} groupTitle={key} key={index} filter={productFilter} />
        ))}
      </ListContainer>
    </Layout>
  );
};

export default Products;
