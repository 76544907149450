import "./App.css";
import React, { useState } from "react";
import { BaseCSS } from "styled-bootstrap-grid";
import { Router, Redirect } from "@reach/router";
import styled from "styled-components";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import PrivateRoute from "./components/PrivateRoute";
import Login from "./pages/Login";
import ResetPassword from "./pages/ResetPassword";
import Products from "./pages/Products";
import ProductDetail from "./pages/ProductDetail";
import InviteMember from "./pages/InviteMember";
import EditRole from "./pages/EditRole";
import Members from "./pages/Members";
import Signup from "./pages/SignUp";
import Training from "./pages/Training";

export const MessageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px;
  background-color: ${(props) => (!!props.isError ? "#ff0000" : "#4BB543")};
  color: #ffffff;
`;

export const UserContext = React.createContext();

export const GlobalErrorMessage = ({ style, message, close, options }) => {
  return (
    <MessageContainer style={style} isError={options.type === "error"}>
      <span>{message}</span>
      <FontAwesomeIcon icon={faTimes} onClick={() => close()} />
    </MessageContainer>
  );
};

function App() {
  const [authState, setAuthState] = useState({
    loading: true,
    currentUser: null,
    userGroups: null,
    jwtToken: null,
    users: null,
  });

  return (
    <>
      <BaseCSS />
      <UserContext.Provider value={{ authState, setAuthState }}>
        <AlertProvider
          position={positions.TOP_CENTER}
          template={GlobalErrorMessage}
          transition={transitions.FADE}
          containerStyle={{ width: "100%", zIndex: "11" }}
          timeout={10000}
        >
          <Router>
            <Redirect from="/" to="/login" />
            <Login path="/login" />
            <Signup path="/signup" />
            <ResetPassword path="/reset-password" />
            <PrivateRoute path="/dealer/products" component={Products} />
            <PrivateRoute path="/dealer/products/:productName" component={ProductDetail} />
            <PrivateRoute path="/members/invite" component={InviteMember} />
            <PrivateRoute path="/members/edit-role" component={EditRole} />
            <PrivateRoute path="/members" component={Members} />
            <PrivateRoute path="/training" component={Training} />
          </Router>
        </AlertProvider>
      </UserContext.Provider>
    </>
  );
}

export default App;
