import styled from "styled-components";
import { Container, Row, media } from "styled-bootstrap-grid";

const PageHeader = styled.section`
  background-color: #d8d8d8;
  width: 100%;
  position: absolute;
  top: 80px;
  height: 70px;

  ${media.lg`
    padding: 35px 0;
    top: 160px;
    height: 140px;
  `}

  ${Row}, ${Container} {
    height: 100%;
  }
`;

export default PageHeader;
