import React, { useMemo } from "react";
import { useTable } from "react-table";
import styled from "styled-components";
import { Container, Col, Row } from "styled-bootstrap-grid";
import VideoPlayButton from "./VideoPlayButton";

const VideoSection = styled.section`
  padding-top: 10px;
`;

const Table = styled.table`
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
`;

const TableHeader = styled.thead`
  border-bottom: 2px solid #111111;
`;

const StyledTh = styled.th`
  padding: 20px 0;
  border: none;
  box-sizing: border-box;
  font-weight: 400;
  position: relative;
  text-align: left;
  vertical-align: top;
`;

const LastHeader = styled(StyledTh)`
  width: 20%;
`;

const TableBodyCell = styled.td`
  padding: 25px 0;
  font-size: 16px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: none;
  :last-child {
    text-align: center;
  }
`;

const Videos = ({ productVideos }) => {
  const columns = useMemo(
    () => [
      {
        Header: "Video Name",
        accessor: "file_name",
      },
      {
        Header: "Description",
        accessor: "description",
      },
    ],
    []
  );

  const videoTableInstance = useTable({ columns, data: productVideos || [] });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = videoTableInstance;

  return (
    <VideoSection>
      <Container>
        <Row>
          <Col col={12}>
            <Table {...getTableProps()}>
              <TableHeader>
                {
                  // Loop over the header rows
                  headerGroups.map((headerGroup) => (
                    // Apply the header row props
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <StyledTh {...column.getHeaderProps()}>{column.render("Header")}</StyledTh>
                      ))}
                      <LastHeader></LastHeader>
                    </tr>
                  ))
                }
                <tr />
              </TableHeader>
              {/* Apply the table body props */}
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return <TableBodyCell {...cell.getCellProps()}>{cell.render("Cell")}</TableBodyCell>;
                      })}
                      <TableBodyCell>
                        <VideoPlayButton videoUrl={row.original.master_link} />
                      </TableBodyCell>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </VideoSection>
  );
};

export default Videos;
