import React from "react";
import styled from "styled-components";

const StyleLabel = styled.label`
  color: #000005;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
`;

const RequireMark = styled.span`
  color: #fec00f;
  font-weight: 600;
`;

const Label = ({ children }) => {
  const asteriskMask = children.slice(children.length - 1);
  return (
    <StyleLabel>
      {asteriskMask === "*" ? (
        <>
          {children.slice(0, -1)}
          <RequireMark>{asteriskMask}</RequireMark>
        </>
      ) : (
        children
      )}
    </StyleLabel>
  );
};

export default Label;
