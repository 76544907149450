import React, { useState } from "react";
import styled from "styled-components";
import * as yup from "yup";
import Auth from "@aws-amplify/auth";
import { Link } from "@reach/router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col, media } from "styled-bootstrap-grid";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import AuthLayout from "../components/AuthLayout";
import Button from "../components/Button";
import ContextTextField from "../components/ContextTextField";

const IconWrapper = styled.span`
  margin-left: 10px;
`;

const StyledIcon = styled(FontAwesomeIcon)`
  font-size: 30px;
`;

const StyledColumn = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  ${media.lg`
    justify-content: flex-end;
  `}
`;

const VerifiedContainer = styled.div`
  width: 100%;
  font-size: 16px;
`;

const MessageContainer = styled.div`
  align-self: start;
  margin-bottom: 30px;
  word-wrap: normal;
`;

const EmailContainer = styled.span`
  font-weight: 700;
`;

const confirmationCodeSchema = yup.object().shape({
  email: yup.string().email().required(),
});

const confirmPasswordSchema = yup.object().shape({
  email: yup.string().email().required(),
  code: yup.string().required(),
  password: yup.string().required(),
});

const ResetPassword = () => {
  const [step, setStep] = useState("confirm-email");

  let params = new URL(window.location).searchParams;
  const resetEmail = params.get("email");

  const sendConfirmationCode = async (data) => {
    try {
      await Auth.forgotPassword(data.email);
      confirmPasswordMethods.setValue("email", data.email);
      setStep("submit-new-password");
    } catch (error) {
      if (error.errors && error.errors.length) {
        sendConfirmationCodeMethods.setError("email", { type: "validate", message: error.errors[0]?.message });
      } else {
        sendConfirmationCodeMethods.setError("email", { type: "validate", message: error.message });
      }
    }
  };

  const confirmPassword = async (data) => {
    try {
      await Auth.forgotPasswordSubmit(data.email, data.code, data.password);

      const cognitoUser = await Auth.signIn(data.email, data.password);
      await Auth.updateUserAttributes(cognitoUser, {
        "custom:password_updated_at": new Date().toISOString(),
      });
      await Auth.signOut();

      setStep("reset-password-complete");
    } catch (error) {
      if (error.errors && error.errors.length) {
        confirmPasswordMethods.setError(error.errors[0]?.message.includes("code") ? "code" : "password", {
          type: "validate",
          message: errorMessage(error.errors[0]?.message),
        });
      } else {
        confirmPasswordMethods.setError(error.message.includes("code") ? "code" : "password", {
          type: "validate",
          message: errorMessage(error.message),
        });
      }
    }
  };

  const errorMessage = (message) => {
    console.log(message);
    if (message.includes("Invalid verification")) return "Invalid verification code";
    else if (message.includes(":")) return message.split(":")[1];
    return message;
  };

  const sendConfirmationCodeMethods = useForm({
    resolver: yupResolver(confirmationCodeSchema),
  });

  const confirmPasswordMethods = useForm({
    resolver: yupResolver(confirmPasswordSchema),
  });

  return (
    <AuthLayout>
      <Container>
        {step === "confirm-email" && (
          <FormProvider {...sendConfirmationCodeMethods}>
            <form onSubmit={sendConfirmationCodeMethods.handleSubmit(sendConfirmationCode)}>
              <Row justifyContent="center">
                <Col col={10} lg={4}>
                  <ContextTextField
                    label="Email address"
                    type="text"
                    name="email"
                    placeholder="Email"
                    defaultValue={resetEmail}
                    disabled={!!resetEmail}
                  />
                </Col>
              </Row>
              <Row justifyContent="center">
                <StyledColumn col={10} lg={4}>
                  <Button type="submit">Send verification code</Button>
                </StyledColumn>
              </Row>
            </form>
          </FormProvider>
        )}
        {step === "submit-new-password" && (
          <FormProvider {...confirmPasswordMethods}>
            <form onSubmit={confirmPasswordMethods.handleSubmit(confirmPassword)}>
              <Row justifyContent="center">
                <StyledColumn col={10} lg={4}>
                  <MessageContainer>
                    We sent a recovery code to you at{" "}
                    <EmailContainer>{confirmPasswordMethods.getValues().email}</EmailContainer>
                  </MessageContainer>
                </StyledColumn>
              </Row>
              <Row justifyContent="center">
                <Col col={10} lg={4}>
                  <ContextTextField name="code" label="Code" />
                </Col>
              </Row>
              <Row justifyContent="center">
                <Col col={10} lg={4}>
                  <ContextTextField label="New Password" type="password" name="password" placeholder="Password" />
                </Col>
              </Row>
              <Row justifyContent="center">
                <StyledColumn col={10} lg={4}>
                  <Button type="submit">
                    Change password
                    <IconWrapper>
                      <StyledIcon icon={faChevronRight} size="1x" />
                    </IconWrapper>
                  </Button>
                </StyledColumn>
              </Row>
            </form>
          </FormProvider>
        )}
        {step === "reset-password-complete" && (
          <Row justifyContent="center">
            <Col col={10} lg={4}>
              <VerifiedContainer>
                Your email is confirmed. Please <Link to="/login">sign in</Link> to continue.
              </VerifiedContainer>
            </Col>
          </Row>
        )}
      </Container>
    </AuthLayout>
  );
};

export default ResetPassword;
