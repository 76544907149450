import styled from "styled-components";
import { media } from "styled-bootstrap-grid";

const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin: 0;

  ${media.lg`
    font-size: 32px;
  `}
`;

export default Title;
