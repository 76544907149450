import React, { useContext } from "react";
import styled from "styled-components";
import Auth from "@aws-amplify/auth";
import { MobileMenuContext } from "./Layout";
import { Link, navigate } from "@reach/router";
import { Container, Col, Row, media } from "styled-bootstrap-grid";
import NegativeLogo from "../images/SPITWATER_CMYK_Logo_Corporate_Negative.png";
import HamburgerButton from "./HamburgerButton";
import { UserContext } from "../App";
import { canManageMembers } from "../utils";

const MenuContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
  background-color: #000005;
  color: #ffffff;
`;

const MobileHeader = styled.nav`
  padding: 0 20px;
  width: 100%;
  height: 190px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
`;

const ImageContainer = styled.div`
  img {
    width: 150px;
  }

  ${media.lg`
    img {
      width: 252px;
      height: 65px;
    }
  `}
`;

const StyledCol = styled(Col)`
  display: flex;
  justify-content: end;
`;

const BaseLink = styled(Link)`
  font-size: 24px;
  letter-spacing: 0;
  line-height: 20px;
  text-decoration: none;
  color: #ffffff;
`;

const ExternalLink = styled.a`
  font-size: 24px;
  letter-spacing: 0;
  line-height: 20px;
  text-decoration: none;
  color: #ffffff;
`;

const MenuContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;

const LinkContainer = styled.div`
  margin-bottom: 50px;
`;

const MobileMenu = () => {
  const { mobileMenu, setMobileMenu } = useContext(MobileMenuContext);
  const { authState } = useContext(UserContext);

  const logout = async () => {
    await Auth.signOut();
    navigate("/login");
  };

  return (
    <MenuContainer>
      <MobileHeader>
        <Container fluid>
          <Row alignItems="center">
            <Col col={8} noGutter>
              <ImageContainer>
                <img src={NegativeLogo} alt="logo" />
              </ImageContainer>
            </Col>
            <StyledCol col={4} noGutter>
              <HamburgerButton showMenu={mobileMenu} setShowMenu={setMobileMenu} />
            </StyledCol>
          </Row>
        </Container>
      </MobileHeader>
      <MenuContent>
        <LinkContainer>
          <BaseLink to="/dealer/products">Products</BaseLink>
        </LinkContainer>
        <LinkContainer>
          <BaseLink to="/training">Training</BaseLink>
        </LinkContainer>
        {canManageMembers(authState.userGroups) && (
          <LinkContainer>
            <BaseLink to="/members">Members</BaseLink>
          </LinkContainer>
        )}
        <LinkContainer>
          <ExternalLink href={process.env.REACT_APP_WEBSITE_LINK} target="blank">
            Website
          </ExternalLink>
        </LinkContainer>
        <LinkContainer>
          <ExternalLink onClick={logout}>Logout</ExternalLink>
        </LinkContainer>
      </MenuContent>
    </MenuContainer>
  );
};

export default MobileMenu;
