import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Layout from "../components/Layout";
import ProductSubHeader from "../components/ProductSubHeader";
import ProductSummary from "../components/ProductSummary";
import Tabs from "../components/Tabs";
import TechnicalSpecifications from "../components/TechnicalSpecifications";
import Documents from "../components/Documents";
import Videos from "../components/Videos";
import { canViewProductResources } from "../utils";
import { UserContext } from "../App";

const ProductDetail = ({ jwtToken, productName, location }) => {
  const [product, setProduct] = useState(null);
  const [selectedTab, setSelectedTab] = useState(null);
  const { authState } = useContext(UserContext);

  useEffect(() => {
    setSelectedTab(location.hash || "#technical-specifications");
  }, [location.hash]);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const queryResult = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/products/${productName}`, {
          headers: {
            Authorization: jwtToken,
          },
        });

        setProduct(queryResult.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchProduct();
  }, [jwtToken, productName]);

  if (!product) return null;
  return (
    <Layout>
      <ProductSubHeader name={product.name} path={product.path} />
      <ProductSummary {...product} />
      <Tabs active={selectedTab} />
      {selectedTab === "#technical-specifications" && <TechnicalSpecifications {...product} />}
      {canViewProductResources(authState.userGroups) && selectedTab === "#documents" && <Documents {...product} jwtToken={jwtToken} />}
      {canViewProductResources(authState.userGroups) && selectedTab === "#videos" && <Videos {...product} />}
    </Layout>
  );
};

export default ProductDetail;
