import styled from "styled-components";

const FieldContainer = styled.div`
  min-height: ${(props) => (props.hasLabel === false ? "0" : "162px")};
  width: 100%;

  p {
    margin-bottom: 0;
  }
`;

export default FieldContainer;
