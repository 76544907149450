import React, { useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { GridThemeProvider } from "styled-bootstrap-grid";
import NavigationBar from "./NavigationBar";
import MobileMenu from "./MobileMenu";
import "bootstrap/dist/css/bootstrap.css";

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  padding: 0;
  margin: 0;

  overflow-x: hidden;
`;

const gridTheme = {
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1400,
  },
  container: {
    maxWidth: {
      xs: 375,
      sm: 540,
      md: 720,
      lg: 960,
      xl: 1140,
    },
  },
};

export const MobileMenuContext = React.createContext({
  mobileMenu: false,
  setMobileMenu: null,
});

const GlobalStyle = createGlobalStyle`
  body {
    overflow: ${(props) => (props.mobileMenuShow ? "hidden" : "unset")};
  }
`;

const Layout = ({ children }) => {
  const [mobileMenu, setMobileMenu] = useState(false);

  return (
    <>
      <GlobalStyle mobileMenuShow={mobileMenu.show} />
      <GridThemeProvider gridTheme={gridTheme}>
        <MobileMenuContext.Provider value={{ mobileMenu, setMobileMenu }}>
          <AppContainer>
            {mobileMenu && <MobileMenu />}
            <NavigationBar />
            {children}
          </AppContainer>
        </MobileMenuContext.Provider>
      </GridThemeProvider>
    </>
  );
};

export default Layout;
