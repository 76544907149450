import React, { useMemo } from "react";
import styled from "styled-components";
import { lowerCase, kebabCase } from "lodash";
import { Link } from "@reach/router";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { useTable, useSortBy } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort, faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import { Container, Col, Row, media } from "styled-bootstrap-grid";

const Table = styled.table`
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
`;

const TableHeader = styled.thead`
  border-bottom: 2px solid #111111;
`;

const StyledTh = styled.th`
  padding: 20px 0;
  border: none;
  box-sizing: border-box;
  font-weight: 400;
  position: relative;
  text-align: left;
  vertical-align: top;

  :last-child {
    width: 50%;
  }
`;

const TableBodyCell = styled.td`
  padding: 20px 0;
  font-size: 16px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: none;
`;

const TableBodyRow = styled.tr`
  :hover {
    background-color: #d8d8d8;
    cursor: pointer;
  }
`;

const MobileLink = styled(Link)`
  color: #111111;
  display: inline-block;
  ${media.lg`
    display: none;
  `}
`;

const DeviceName = styled.span`
  color: #111111;
  display: none;
  ${media.lg`
    display: inline-block;
  `}
`;

const StyledTooltip = styled(Tooltip)`
  .tooltip-inner {
    text-align: left;
  }
`;

const StyledIcon = styled(FontAwesomeIcon)`
  margin-left: 10px;
`;

const ProductTable = ({ productsData }) => {
  const columns = useMemo(
    () => [
      {
        Header: "Model Number",
        accessor: "name",
      },
      {
        Header: "SKU",
        accessor: "code",
      },
      {
        Header: "Description",
        accessor: "description",
      },
    ],
    []
  );

  const productTableInstance = useTable({ columns, data: productsData }, useSortBy);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = productTableInstance;

  if (!productsData.length) {
    return (
      <Container>
        <Row justifyContent="center">
          <p>
            <i>No content available that matches your filters.</i>
          </p>
        </Row>
      </Container>
    );
  }

  return (
    <Container>
      <Row>
        <Col col={12}>
          <Table {...getTableProps()}>
            <TableHeader>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <StyledTh {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render("Header")}
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <StyledIcon size="sm" icon={faSortDown} />
                          ) : (
                            <StyledIcon size="sm" icon={faSortUp} />
                          )
                        ) : (
                          <StyledIcon size="sm" icon={faSort} />
                        )}
                      </span>
                    </StyledTh>
                  ))}
                </tr>
              ))}
              <tr />
            </TableHeader>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <TableBodyRow
                    {...row.getRowProps()}
                    onClick={() =>
                      (window.location.href = `/dealer/products/${kebabCase(lowerCase(row.original.name))}`)
                    }
                  >
                    {row.cells.map((cell) => {
                      if (cell.column.Header === "Model Number") {
                        return (
                          <TableBodyCell {...cell.getCellProps()}>
                            <MobileLink to={"/dealer/products/" + kebabCase(lowerCase(row.original.name))}>
                              {cell.render("Cell")}
                            </MobileLink>
                            <DeviceName>{cell.render("Cell")}</DeviceName>
                          </TableBodyCell>
                        );
                      } else if (cell.column.Header === "Description") {
                        return (
                          <TableBodyCell {...cell.getCellProps()}>
                            <OverlayTrigger
                              delay={{ hide: 450, show: 300 }}
                              overlay={(props) => <StyledTooltip {...props}>{cell.render("Cell")}</StyledTooltip>}
                              placement="bottom"
                            >
                              <div>{cell.render("Cell")}</div>
                            </OverlayTrigger>
                          </TableBodyCell>
                        );
                      } else return <TableBodyCell {...cell.getCellProps()}>{cell.render("Cell")}</TableBodyCell>;
                    })}
                  </TableBodyRow>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default ProductTable;
