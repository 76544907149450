import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

const HammburgContainer = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-content: center;
  font-size: 24px;
  color: #fec00f;
`;

const HamburgerButton = ({ showMenu, setShowMenu }) => {
  return (
    <HammburgContainer onClick={() => setShowMenu(!showMenu)}>
      {!showMenu ? <FontAwesomeIcon icon={faBars} size="1x" /> : <FontAwesomeIcon icon={faTimes} size="1x" />}
    </HammburgContainer>
  );
};

export default HamburgerButton;
