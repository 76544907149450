import React from "react";
import styled from "styled-components";
import BaseSelect, { components } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

const StyledBaseSelect = styled(BaseSelect)`
  font-size: 18px;
  color: #43525a;
  width: 100%;

  .react-select__placeholder {
    width: calc(100% - 32px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #7d858a;
    font-style: italic;
    font-size: 16px;
  }

  .react-select__clear-indicator {
    padding: 0;
    padding-right: 8px;
  }

  .react-select__control {
    align-items: center;
    border-radius: 8px;
    width: 100%;
    border-style: solid;
    border-width: 1px;
    box-shadow: none;
    cursor: default;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    box-sizing: border-box;
    padding: 12px 9px;
    outline: 0px !important;
    color: #43525a;
    transition: all 0ms;
  }
  .react-select__control--is-focused {
    border-color: #fec00f !important;
    background-color: #ffffff;
  }

  .react-select__control--menu-is-open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .react-select__indicator-separator {
    display: none;
  }
  .react-select__option {
    box-sizing: border-box;
    color: #021740;
    padding: 20px;
    border-top: 1px solid #fec00f;

    &:first-child {
      border-top: 0;
    }
  }
  .react-select__option--is-focused {
    background-color: #ffffff;
  }
  .react-select__option--is-selected {
    background-color: #fec00f;
    font-weight: 700;
  }

  .react-select__single-value {
    overflow: visible;
  }

  .react-select__menu {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: 0;
    padding: 0;
    border: 1px solid #fec00f;
    border-top: 0;
    box-shadow: none;
    z-index: 101;
  }

  .react-select__menu-list {
    padding: 0;
  }
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: #43525a;
`;

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <StyledFontAwesomeIcon icon={faChevronDown} />
  </components.DropdownIndicator>
);

const Select = (props) => {
  const { name, options, placeholder } = props;
  return (
    <StyledBaseSelect
      classNamePrefix="react-select"
      isSearchable={false}
      components={{ DropdownIndicator }}
      options={options}
      placeholder={placeholder}
      isClearable
      aria-label={`select ${name}`}
      {...props}
    />
  );
};

export default Select;
