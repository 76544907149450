import React, { useMemo } from "react";
import styled from "styled-components";
import { useTable } from "react-table";
import { Container, Col, Row } from "styled-bootstrap-grid";
import DocumentActions from "./DocumentActions";

const DocumentSection = styled.section`
  padding-top: 10px;
`;

const Table = styled.table`
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
`;

const TableHeader = styled.thead`
  border-bottom: 2px solid #111111;
`;

const StyledTh = styled.th`
  padding: 20px 0;
  border: none;
  box-sizing: border-box;
  font-weight: 400;
  position: relative;
  text-align: left;
  vertical-align: top;
`;

const LastHeader = styled(StyledTh)`
  width: 20%;
`;

const TableBodyCell = styled.td`
  padding: 25px 0;
  font-size: 16px;
  text-align: left;
  border: none;
  :last-child {
    text-align: center;
  }
`;

const Documents = ({ productResources, jwtToken }) => {
  const columns = useMemo(
    () => [
      {
        Header: "Document Name",
        accessor: "file_name",
      },
      {
        Header: "Description",
        accessor: "description",
      },
    ],
    []
  );

  const documentTableInstance = useTable({ columns, data: productResources || [] });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = documentTableInstance;

  return (
    <DocumentSection>
      <Container>
        <Row>
          <Col col={12}>
            <Table {...getTableProps()}>
              <TableHeader>
                {
                  // Loop over the header rows
                  headerGroups.map((headerGroup) => (
                    // Apply the header row props
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <StyledTh {...column.getHeaderProps()}>{column.render("Header")}</StyledTh>
                      ))}
                      <LastHeader></LastHeader>
                    </tr>
                  ))
                }
                <tr />
              </TableHeader>
              {/* Apply the table body props */}
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return <TableBodyCell {...cell.getCellProps()}>{cell.render("Cell")}</TableBodyCell>;
                      })}
                      <TableBodyCell>
                        <DocumentActions
                          fileId={row.original.id}
                          fileName={!row.original.id ? row.original.file_name : ""}
                          jwtToken={jwtToken}
                        />
                      </TableBodyCell>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </DocumentSection>
  );
};

export default Documents;
