import React, { useState, useContext } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import styled from "styled-components";
import { useForm, FormProvider } from "react-hook-form";
import { Container, Row, Col, media } from "styled-bootstrap-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { Link } from "@reach/router";
import { Spinner } from "react-bootstrap";
import { useAlert } from "react-alert";
import Layout from "../components/Layout";
import Button from "../components/Button";
import ContextInput from "../components/ContextInput";
import ContextSelect from "../components/ContextSelect";
import Title from "../components/Title";
import PageHeader from "../components/PageHeader";
import { UserContext } from "../App";

const ContactFormSection = styled.section`
  background-color: #ffffff;
  padding: 50px 0;
  margin-bottom: 100px;
  margin-top: 150px;

  ${media.lg`
    margin-top:300px;
  
  `}
`;

const StyledButton = styled(Button)`
  width: 100%;
  background-color: #fec00f;
  color: #000005;
  :hover {
    background-color: #fec932;
    border-color: #fec932;
  }
  :active {
    background-color: #feb30b;
    border-color: #feb30b;
  }
`;

const SuccessEnquiryMessage = styled.p`
  top: 80px;
  left: 0;
  color: #000005;
  font-size: 20px;
  letter-spacing: 0;
  text-align: left;
`;

const ErrorEnquiryMessage = styled.p`
  top: 80px;
  left: 0;
  color: #ff0000;
  font-size: 20px;
  letter-spacing: 0;
  text-align: left;
`;

const StyledLink = styled(Link)`
  font-size: 16px;
  color: #111111;
  margin-bottom: 10px;

  span {
    margin-left: 10px;
  }
`;

const BackLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const contactFormSchema = yup.object().shape({
  email: yup.string().email().required(),
  group: yup.string().required(),
});

const GROUP_MAP = [
  {
    label: "SSCG",
    value: "SSCG",
  },
  {
    label: "Distributor Team Member",
    value: "DistributorTeamMember",
  },
  {
    label: "Manufacturer",
    value: "Manufacturer",
  },
  {
    label: "Platinum Dealer",
    value: "PlatinumDealer",
  },
  {
    label: "Gold Dealer",
    value: "GoldDealer",
  },
  {
    label: "Silver Dealer",
    value: "SilverDealer",
  },
  {
    label: "International Dealer",
    value: "InternationalDealer",
  },
  {
    label: "Innoflex Admin",
    value: "InnoflexAdmin",
  },
  {
    label: "SW Admin",
    value: "SWAdmin",
  },
];

const InviteMember = ({ jwtToken }) => {
  const [submitStatus, setSubmitStatus] = useState();
  const [invitationLink, setInvitationLink] = useState("");
  const { authState } = useContext(UserContext);
  const alert = useAlert();

  const contactFormMethods = useForm({
    resolver: yupResolver(contactFormSchema),
  });

  let params = new URL(window.location).searchParams;
  let reinviteEmail = params.get("email");

  const createInvitation = async (data) => {
    let allUsers = [];

    allUsers = authState.users.map((item) => item.email.toLowerCase());
    if (allUsers.includes(data.email.toLowerCase()) && !reinviteEmail) {
      contactFormMethods.setError("email", {
        type: "custom",
        message: "This email is already a member. Please try again with different email.",
      });
    } else if (reinviteEmail) {
      const config = {
        method: "put",
        url: `${process.env.REACT_APP_API_ENDPOINT}/invitations`,
        headers: {
          Authorization: jwtToken,
          "Content-Type": "application/json",
        },
        data: {
          ...data,
          email: data.email.toLowerCase(),
        },
      };
      axios(config)
        .then((response) => {
          setInvitationLink(
            `${window.location.origin}/signup?code=${response.data.code}&email=${data.email.toLowerCase()}`
          );
          setSubmitStatus(response.status);
        })
        .catch((error) => {
          if (error.response.status === 403) {
            alert.error("Forbidden to access this resources.");
          }
          setSubmitStatus(error.response.status);
        });
    } else {
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_ENDPOINT}/invitations`,
        headers: {
          Authorization: jwtToken,
          "Content-Type": "application/json",
        },
        data: {
          ...data,
          email: data.email.toLowerCase(),
        },
      };
      axios(config)
        .then((response) => {
          setInvitationLink(
            `${window.location.origin}/signup?code=${response.data.code}&email=${data.email.toLowerCase()}`
          );
          setSubmitStatus(response.status);
        })
        .catch((error) => {
          if (error.response.status === 403) {
            alert.error("Forbidden to access this resources.");
          }
          setSubmitStatus(error.response.status);
        });
    }
  };

  const isSubmitted = () => [200, 400, 403, 404, 502].indexOf(submitStatus) > -1;
  return (
    <Layout>
      <PageHeader>
        <Container>
          <Row justifyContent="center" alignItems="center">
            <Col col={12} lg={8}>
              <BackLinkContainer>
                <StyledLink to="/members">
                  <FontAwesomeIcon icon={faChevronLeft} size="1x" />
                  <span>Back</span>
                </StyledLink>
                <Title>Invite member</Title>
              </BackLinkContainer>
            </Col>
          </Row>
        </Container>
      </PageHeader>
      <ContactFormSection>
        <Container>
          {!isSubmitted() && (
            <FormProvider {...contactFormMethods}>
              <form>
                <Row justifyContent="center">
                  <Col col={12} lg={8}>
                    <ContextInput
                      name="email"
                      placeholder="Enter your email address"
                      label="Email Address*"
                      defaultValue={reinviteEmail || ""}
                      isDisabled={!!reinviteEmail}
                    />
                  </Col>
                </Row>
                <Row justifyContent="center">
                  <Col col={12} lg={8}>
                    <ContextSelect name="group" placeholder="Choose your group" label="Group*" options={GROUP_MAP} />
                  </Col>
                </Row>

                <Row>
                  <Col col={12} lg={2} lgOffset={8}>
                    {!contactFormMethods.formState.isSubmitting && (
                      <StyledButton type="button" onClick={contactFormMethods.handleSubmit(createInvitation)}>
                        Submit
                      </StyledButton>
                    )}
                    {contactFormMethods.formState.isSubmitting && (
                      <StyledButton type="button" disabled>
                        <Spinner animation="border" size="sm"></Spinner>
                      </StyledButton>
                    )}
                  </Col>
                </Row>
              </form>
            </FormProvider>
          )}
          {isSubmitted() && (
            <Row justifyContent="center">
              <Col col={12} lg={8}>
                {submitStatus === 200 && (
                  <SuccessEnquiryMessage>
                    Invitation is generated. Here is the invitation link:
                    <br />
                    <a target="_blank" rel="noreferrer" href={invitationLink}>
                      {invitationLink}
                    </a>
                  </SuccessEnquiryMessage>
                )}
                {[400, 403, 404].includes(submitStatus) && (
                  <ErrorEnquiryMessage>Oops! It’s us not you. An error occurred whilst submitting.</ErrorEnquiryMessage>
                )}
                {submitStatus === 502 && (
                  <ErrorEnquiryMessage>
                    Oops! We had an issue submitting your request over the network. Please refresh the page and try
                    again.
                  </ErrorEnquiryMessage>
                )}
              </Col>
            </Row>
          )}
        </Container>
      </ContactFormSection>
    </Layout>
  );
};

export default InviteMember;
