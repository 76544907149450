import React from "react";
import styled from "styled-components";
import { ErrorMessage } from "@hookform/error-message";
import { useFormContext, Controller } from "react-hook-form";
import Input from "./Input";
import FieldMessage from "./FieldMessage";
import FieldContainer from "./FieldContainer";
import Label from "./Label";

const InputContainer = styled.div`
  background-color: #ffffff;
  color: #43525a;
  cursor: text;
  box-sizing: border-box;
  margin-top: ${(props) => (props.hasLabel ? "30px" : "0")};
  border-radius: 8px;
  border: 1px solid;
  border-color: hsl(0, 0%, 80%);

  :focus-within {
    border-color: #fec00f;
  }
`;

const ControlContainer = styled.div`
  width: 100%;
`;

const ContextInput = ({ name, label, defaultValue = "", type, placeholder, isDisabled }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <FieldContainer hasLabel={!!label}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={(controllerProps) => (
          <ControlContainer>
            {label && <Label>{label}</Label>}
            <InputContainer hasLabel={!!label}>
              <Input
                type={type || "text"}
                placeholder={placeholder}
                onChange={controllerProps.field.onChange}
                value={controllerProps.field.value}
                isDisabled={isDisabled}
              />
            </InputContainer>
          </ControlContainer>
        )}
      />
      {label && (
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => <FieldMessage>{message.replace(name, label).replace("*", "")}</FieldMessage>}
        />
      )}
    </FieldContainer>
  );
};

export default ContextInput;
