import React from "react";
import { Container, Row, Col } from "styled-bootstrap-grid";
import TextField from "./TextField";
import Title from "../components/Title";
import PageHeader from "../components/PageHeader";

const Filter = ({ label, onChangeFilter }) => {
  return (
    <PageHeader>
      <Container>
        <Row justifyContent="between" alignItems="center">
          <Col col={2} lg={2}>
            <Title>{label}</Title>
          </Col>
          <Col col={7} lg={5}>
            <TextField label="" placeholder="Search Products" onChange={onChangeFilter} />
          </Col>
        </Row>
      </Container>
    </PageHeader>
  );
};

export default Filter;
