import React from "react";
import styled from "styled-components";
import _ from "lodash";
import { Link } from "@reach/router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col, media } from "styled-bootstrap-grid";
import PageHeader from "../components/PageHeader";

const BackLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledLink = styled(Link)`
  font-size: 16px;
  color: #111111;
  margin-bottom: 10px;
  margin-right: 5px;

  span {
    margin-left: 10px;
  }

  ${media.lg`
    margin-right: 20px;
  `}
`;

const ProductNameLabel = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin: 0;
`;

const BreadcrumbContainer = styled.div`
  display: flex;
`;

const ProductSubHeader = ({ name, path }) => {
  const groupQuery = (index) =>
    _.join(_.dropRight(path, path.length - index - 1).map((el) => _.kebabCase(_.lowerCase(el)), ","));

  return (
    <PageHeader>
      <Container>
        <Row alignItems="center" justifyContent="between">
          <Col col={12}>
            <BackLinkContainer>
              <BreadcrumbContainer>
                {path.map((item, index) => (
                  <StyledLink key={index} to={"/dealer/products" + (groupQuery ? "?path=" + groupQuery(index) : "")}>
                    <FontAwesomeIcon icon={faChevronLeft} size="1x" />
                    <span>{item}</span>
                  </StyledLink>
                ))}
              </BreadcrumbContainer>

              <ProductNameLabel>{name}</ProductNameLabel>
            </BackLinkContainer>
          </Col>
          {/* <Col col={3}>
            <ButtonContainer>
              <Button>Add this to cart</Button>
            </ButtonContainer>
          </Col> */}
        </Row>
      </Container>
    </PageHeader>
  );
};

export default ProductSubHeader;
