import React, { useContext } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "styled-bootstrap-grid";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { canViewProductPrice } from "../utils";
import { UserContext } from "../App";

const StyledTooltip = styled(Tooltip)`
  .tooltip-inner {
    text-align: left;
  }
`;

const TechnicalSection = styled.section`
  padding-top: 10px;
`;

const TechText = styled.span`
  font-size: 16px;
  height: 50px;
  align-items: center;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const TooltipText = styled.span`
  font-size: 16px;
  align-items: left;
  display: flex;
`;

const TechnicalSpecifications = ({ technicalSpecifications }) => {
  const { authState } = useContext(UserContext);

  let filteredTechSpecs = [];
  if (!canViewProductPrice(authState.userGroups)) {
    filteredTechSpecs = technicalSpecifications.filter((item) => item.label !== "List Price (ex GST)");
  } else {
    filteredTechSpecs = technicalSpecifications;
  }

  return (
    <TechnicalSection>
      <Container>
        {filteredTechSpecs
          .sort((a, b) => a.priority - b.priority)
          .map((tech, index) => (
            <Row key={String(index)}>
              <Col col={6}>
                <TechText>{tech.label}</TechText>
              </Col>
              <Col col={6}>
                {tech.label === "Recommended Retail Price (ex GST)" ? (
                  <TechText>${tech.value}</TechText>
                ) : (
                  <OverlayTrigger
                    delay={{ hide: 450, show: 300 }}
                    overlay={(props) => (
                      <StyledTooltip {...props}>
                        <TooltipText>{tech.value}</TooltipText>
                      </StyledTooltip>
                    )}
                    placement="bottom"
                  >
                    <TechText>{tech.value}</TechText>
                  </OverlayTrigger>
                )}
              </Col>
            </Row>
          ))}
      </Container>
    </TechnicalSection>
  );
};

export default TechnicalSpecifications;
