import React, { useContext, useState } from "react";
import Modal from "react-modal";
import styled from "styled-components";
import axios from "axios";
import { pdfjs, Document, Page } from "react-pdf";
import { Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { ValidateElement } from "../utils";
import { UserContext } from "../App";
import "react-pdf/dist/cjs/Page/AnnotationLayer.css";
import "react-pdf/dist/cjs/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = process.env.PUBLIC_URL + "/pdf.worker.min.js";

const DOCUMENT_SCALES = [0.25, 0.33, 0.5, 0.67, 0.75, 0.8, 0.9, 1, 1.1, 1.25, 1.5, 1.75, 2, 2.5, 3, 4, 5];

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    width: "80%",
    minHeight: "90%",
    transform: "translate(-50%, -50%)",
    color: "#111111",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
  },
};

const StyledLink = styled.p`
  text-decoration: underline;
  color: #111111;
  cursor: pointer;
  margin-right: 20px;
`;

const StyledIcon = styled(FontAwesomeIcon)`
  position: fixed;
  z-index: 1001;
  top: 6%;
  right: 12%;
  color: #000005;
  cursor: pointer;
  margin-right: -15px;
`;

const ModalFooter = styled.div`
  position: fixed;
  width: 80%;
  z-index: 1001;
  bottom: 6%;
  left: 10%;
  display: flex;
  justify-content: center;
  opacity: 0.4;
  transition: opacity 0.5s linear;

  :hover {
    opacity: 1;
  }
`;

const HeaderContent = styled.div`
  width: 100px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #525659;
  opacity: 0.8;
  border-radius: 10px;
`;

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  .react-pdf__Document {
    .react-pdf__Page {
      display: flex;
      justify-content: center;
    }
  }
`;

const DownloadContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100px;
`;

const ActionContainer = styled.div`
  display: flex;
`;

const ZoomBox = styled.div`
  height: 70%;
  background-color: #111111;
  color: #ffffff;
  margin: 0 2px;
  padding: 0 2px;
  border-radius: 3px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 42px;
`;

const ZoomButton = styled.button`
  border: none;
  outline: none;
  background: transparent;
  color: #ffffff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    background-color: #8d8d8d;
  }

  :disabled {
    color: #6a6a6a;
    background: transparent;
  }
`;

const DocumentActions = ({ fileId, jwtToken, fileName }) => {
  const [openModal, setOpenModal] = useState(false);
  const [documentLoaded, setDocumentLoaded] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [downloading, setDownloading] = useState(false);
  const [fileUrl, setFileUrl] = useState();
  const [zoomIndex, setZoomIndex] = useState(7);
  const { authState } = useContext(UserContext);

  const onDocumentLoadSuccess = (numPages) => {
    setNumPages(numPages);
    setDocumentLoaded(true);
  };

  const onCloseModal = () => {
    setOpenModal(false);
    setDocumentLoaded(false);
  };

  const fetchDocument = async (isDownloading = false) => {
    const uri = encodeURI(fileId || fileName.replace(/\//g, "+"));

    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_ENDPOINT}/documents/${uri}`,
      headers: {
        Authorization: jwtToken,
        "Content-Type": "application/json",
      },
      requestContext: {
        authorizer: {
          claims: {
            sub: authState.currentUser.attributes.sub,
          },
        },
      },
    };

    try {
      const result = await axios(config);
      if (isDownloading) {
        window.open(result.data.signedDownloadUrl, "_blank");
      }
      setFileUrl(result.data.signedDownloadUrl);
    } catch (error) {
      console.log("error", error);
    }
  };

  const onDownloadFile = async () => {
    setDownloading(true);
    await fetchDocument(true);
    setDownloading(false);
  };

  const onViewDocument = async () => {
    setOpenModal(true);
    await fetchDocument();
  };

  return (
    <ActionContainer>
      {openModal && <StyledIcon onClick={onCloseModal} icon={faTimes} size="1x" />}
      {openModal && documentLoaded && (
        <ModalFooter>
          <HeaderContent>
            <ZoomButton disabled={zoomIndex - 1 < 0} onClick={() => setZoomIndex(zoomIndex - 1)}>
              <FontAwesomeIcon icon={faMinus} size="xs" />
            </ZoomButton>
            <ZoomBox>{Math.trunc(DOCUMENT_SCALES[zoomIndex] * 100)}%</ZoomBox>
            <ZoomButton disabled={DOCUMENT_SCALES[zoomIndex] >= 5} onClick={() => setZoomIndex(zoomIndex + 1)}>
              <FontAwesomeIcon icon={faPlus} size="xs" />
            </ZoomButton>
          </HeaderContent>
        </ModalFooter>
      )}
      <Modal isOpen={openModal} onRequestClose={onCloseModal} style={modalStyles} ariaHideApp={false}>
        <ContentContainer>
          {fileUrl && (
            <Document file={fileUrl} onLoadSuccess={(document) => onDocumentLoadSuccess(document._pdfInfo.numPages)}>
              {[...Array(numPages).keys()].map((page, index) => (
                <Page
                  scale={DOCUMENT_SCALES[zoomIndex]}
                  width={window.innerWidth * 0.75}
                  pageNumber={page + 1}
                  key={index}
                />
              ))}
            </Document>
          )}
          {!fileUrl && <Spinner animation="border" variant="warning" size="md"></Spinner>}
        </ContentContainer>
      </Modal>
      <ValidateElement component={StyledLink} actualFunction={onViewDocument}>
        View
      </ValidateElement>
      <DownloadContainer>
        {!downloading && (
          <ValidateElement component={StyledLink} actualFunction={onDownloadFile}>
            Download
          </ValidateElement>
        )}
        {downloading && <Spinner animation="border" variant="warning" size="md"></Spinner>}
      </DownloadContainer>
    </ActionContainer>
  );
};

export default DocumentActions;
