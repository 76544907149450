import React, { useState, useEffect } from "react";
import { Col, Row } from "styled-bootstrap-grid";
import styled from "styled-components";
import ModalVideo from "react-modal-video";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faChevronDown, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { ValidateElement } from "../utils";

const VideoContainer = styled.div`
  background-color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  margin-bottom: 10px;
`;

const VideoThumbnail = styled.img`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
`;

const PlayButton = styled.div`
  width: 100px;
  height: 100px;
  border-style: solid;
  border-width: 4px;
  border-color: #fbba18;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fbba18;
  position: absolute;
  z-index: 1;
`;

const GroupHeading = styled.h2`
  font-size: 18px;
  font-weight: bold;
  margin: 20px 0;
`;

const HeadingRow = styled(Row)`
  padding: 0 15px;
`;

const StyledHorizontalLine = styled.hr`
  height: 2px;
  border: 1px solid #bbc5cc;
  margin: 0;

  :first-child {
    display: none;
  }
`;

const VideoGroups = ({ videoData }) => {
  const [showVideos, setShowVideos] = useState(true);
  const [showVideo, setShowVideo] = useState(false);
  const [video, setVideo] = useState(null);

  const playVideo = (video) => {
    setVideo(video);
    setShowVideo(true);
  };

  useEffect(() => {
    if (showVideo) {
      const collection = document.getElementsByClassName("modal-video-close-btn");
      if (collection[0]) {
        collection[0].onclick = (event) => {
          setShowVideo(false);
        };
      }
    }
  }, [setShowVideo, showVideo]);

  return (
    <>
      <StyledHorizontalLine />

      <HeadingRow justifyContent="between" alignItems="center" onClick={() => setShowVideos(!showVideos)}>
        <GroupHeading>{videoData.groupTitle}</GroupHeading>

        {showVideos ? (
          <FontAwesomeIcon icon={faChevronDown} size="lg" />
        ) : (
          <FontAwesomeIcon icon={faChevronLeft} size="lg" />
        )}
      </HeadingRow>
      {showVideos && (
        <Row>
          {videoData.videos.map((video, index) => (
            <Col col={8} lg={3} key={index} offset={2} lgOffset={0}>
              <VideoContainer>
                <VideoThumbnail src={video.thumbnail} alt={""} />
                <ValidateElement component={PlayButton} actualFunction={() => playVideo(video)}>
                  <FontAwesomeIcon icon={faPlay} size="2x" />
                </ValidateElement>
              </VideoContainer>
              <p>{video.title}</p>
            </Col>
          ))}

          {video && (
            <ModalVideo channel="custom" videoId={video.url} isOpen={showVideo}>
              <video autoPlay controls name="media" width="100%" onContextMenu={(e) => e.preventDefault()}>
                <source src={video.url} type="video/mp4" />
              </video>
            </ModalVideo>
          )}
        </Row>
      )}
    </>
  );
};

export default VideoGroups;
