import React from "react";
import styled from "styled-components";
import { media } from "styled-bootstrap-grid";

const TextFieldContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 100%;
`;
const TextInput = styled.input`
  font-size: 16px;
  border: 0;
  overflow: hidden;
  padding: 15px 25px;
  border-radius: 5px;
  border: 0;
  background-color: ${(props) => (props.disabled ? "#c2c2c2" : "#ffffff")};

  &::placeholder {
    color: #79838c;
    font-style: italic;
  }

  ${media.lg`
    padding: 21px 25px;
  `}
`;

const Label = styled.label`
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 15px;
`;

const TextField = ({ label, ...rest }) => {
  return (
    <TextFieldContainer>
      {label && <Label>{label}</Label>}
      <TextInput {...rest} />
    </TextFieldContainer>
  );
};

export default TextField;
