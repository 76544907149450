import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "@reach/router";
import qs from "qs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRotateLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col } from "styled-bootstrap-grid";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Auth from "@aws-amplify/auth";
import Amplify from "@aws-amplify/core";

import AuthLayout from "../components/AuthLayout";
import Button from "../components/Button";
import ContextTextField from "../components/ContextTextField";
import axios from "axios";

const awsconfig = {
  aws_project_region: "ap-southeast-2",
  aws_cognito_region: "ap-southeast-2",
  aws_user_pools_id: process.env.REACT_APP_COGNITO_POOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_COGNITO_CLIENT_ID,
  oauth: {},
};

Amplify.configure({
  ...awsconfig,
});

const StyledRow = styled(Row)`
  margin-top: 20px;
  margin-bottom: 40px;
  padding-left: 40px;
  padding-right: 40px;
`;

const LoginLink = styled.p`
  font-size: 16px;
  color: #ffffff;
  text-align: center;
`;

const IconWrapper = styled.span`
  margin-left: 10px;
`;

// const TermsContainer = styled.div`
//   height: 300px;
//   overflow: hidden;
//   border-radius: 5px;
//   margin-top: 15px;
// `;

// const TermsContent = styled.div`
//   padding: 20px;
//   line-height: 1.5;
//   background-color: #ffffff;
//   color: #000005;
//   height: 100%;
//   overflow-y: hidden;
//   :active,
//   :hover,
//   :focus {
//     overflow-y: scroll;
//     ${media.lg`
//     padding-right: 0;
//     `}
//   }
// `;

const StyledIcon = styled(FontAwesomeIcon)`
  font-size: 30px;
`;

const StyledColumn = styled(Col)`
  display: flex;
  align-items: center;
`;

const ResendButton = styled(Button)`
  min-width: 145px;
  padding: 15px 25px;
  display: flex;
  flex-direction: column;
  height: 62px;
  background-color: ${(props) => (props.disabled ? "#bdbdbd" : "#fec00f")};
`;

const ResendText = styled.div`
  display: flex;
  align-items: center;
`;

// const Label = styled.label`
//   font-size: 16px;
//   color: #ffffff;
// `;

const signUpSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup
    .string()
    .required()
    .min(8)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
      "Passwords must contain at least 1 upper case, 1 lower case, 1 numeric and must be at least 8 characters in length."
    ),
  code: yup.string().required("Invitation code is missing. Please use the provided invite link."),
});

const confirmationSchema = yup.object().shape({
  email: yup.string().email().required(),
  confirmationCode: yup.string().required(),
});

const SignUp = ({ location }) => {
  const queryString = qs.parse(location.search, { ignoreQueryPrefix: true });
  const { code: qsCode, email: userEmail } = queryString;

  const [countDown, setCountDown] = useState(0);
  const [runTimer, setRunTimer] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [step, setStep] = useState("sign-up");

  const fetchUser = useCallback(async () => {
    if (userEmail) {
      let user;
      signUpFormMethods.setValue("email", userEmail);
      try {
        user = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/getUser/${userEmail}`);
        if (user.data) {
          user = user.data.user;
        }
      } catch (error) {
        console.log(error);
      }
      if (Object.keys(user).length !== 0 && user.Enabled === true && user.UserStatus === "UNCONFIRMED") {
        confirmationFormMethods.setValue("email", userEmail);
        setStep("confirmation-code");
      }
    }
  }, [userEmail]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  const seconds = String(countDown % 60).padStart(2, 0);
  const minutes = String(Math.floor(countDown / 60)).padStart(2, 0);

  useEffect(() => {
    let timerId;

    if (runTimer) {
      setCountDown(60 * 5);
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }

    return () => clearInterval(timerId);
  }, [runTimer]);

  const handleResendCode = async (e) => {
    e.preventDefault();
    setDisabled(true);
    setRunTimer((t) => !t);
    await Auth.resendSignUp(userEmail);
  };

  const signUp = async (data) => {
    const { code, email, password } = data;

    try {
      await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/signup?code=${code}`, {
        email,
        password,
      });
      confirmationFormMethods.setValue("email", email);
      setStep("confirmation-code");
    } catch (error) {
      if (error.response && error.response.data) {
        let message = error.response.data;
        if (message.includes("Password did not conform with policy")) {
          message =
            "Invalid password. Requires a minimum length of 8 characters including an uppercase letter, a lowercase letter, and a number";
        }
        signUpFormMethods.setError("password", { type: "validate", message });
      } else {
        signUpFormMethods.setError("password", { type: "validate", message: error.message });
      }
    }
  };

  const confirmSignUp = async (data) => {
    const { email, confirmationCode } = data;
    try {
      await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/confirm`, {
        email,
        confirmationCode,
      });
      setStep("signup-success");
    } catch (error) {
      console.log(error.response);
    }
  };

  const signUpFormMethods = useForm({
    resolver: yupResolver(signUpSchema),
    defaultValues: {
      code: qsCode,
    },
  });

  const confirmationFormMethods = useForm({
    resolver: yupResolver(confirmationSchema),
  });

  return (
    <AuthLayout>
      <Container>
        <FormProvider {...signUpFormMethods}>
          <form onSubmit={signUpFormMethods.handleSubmit(signUp)}>
            {step === "sign-up" && (
              <>
                <Row justifyContent="center">
                  <Col lg={4} md={6} sm={8} xs={10}>
                    <ContextTextField
                      label="Invitation code"
                      type="text"
                      name="code"
                      placeholder="Invitation code"
                      disabled
                    />
                  </Col>
                </Row>
                <Row justifyContent="center">
                  <Col lg={4} md={6} sm={8} xs={10}>
                    <ContextTextField label="First name" type="text" name="firstname" placeholder="First name" />
                  </Col>
                </Row>
                <Row justifyContent="center">
                  <Col lg={4} md={6} sm={8} xs={10}>
                    <ContextTextField label="Last name" type="text" name="lastname" placeholder="Last name" />
                  </Col>
                </Row>
                <Row justifyContent="center">
                  <Col lg={4} md={6} sm={8} xs={10}>
                    <ContextTextField label="Phone number" type="text" name="phone" placeholder="Phone number" />
                  </Col>
                </Row>
                <Row justifyContent="center">
                  <Col lg={4} md={6} sm={8} xs={10}>
                    <ContextTextField label="Email address" type="text" name="email" placeholder="Email" disabled />
                  </Col>
                </Row>
                <Row justifyContent="center">
                  <Col lg={4} md={6} sm={8} xs={10}>
                    <ContextTextField label="Password" type="password" name="password" placeholder="Password" />
                  </Col>
                </Row>
                {/* <Row justifyContent="center">
                  <Col lg={4} md={6} sm={8} xs={10}>
                    <Label>Terms and conditions</Label>
                    <TermsContainer>
                      <TermsContent>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis cumque amet voluptas nulla
                        harum suscipit est, veniam soluta recusandae officia pariatur necessitatibus minus laborum
                        reiciendis odio enim inventore eum dolor. Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Veritatis cumque amet voluptas nulla harum suscipit est, veniam soluta recusandae officia
                        pariatur necessitatibus minus laborum reiciendis odio enim inventore eum dolor. Lorem ipsum
                        dolor sit amet, consectetur adipisicing elit. Veritatis cumque amet voluptas nulla harum
                        suscipit est, veniam soluta recusandae officia pariatur necessitatibus minus laborum reiciendis
                        odio enim inventore eum dolor. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                        Veritatis cumque amet voluptas nulla harum suscipit est, veniam soluta recusandae officia
                        pariatur necessitatibus minus laborum reiciendis odio enim inventore eum dolor.
                      </TermsContent>
                    </TermsContainer>
                  </Col>
                </Row> */}
                <StyledRow justifyContent="center">
                  <Col lg={4} md={6} sm={8} xs={10}>
                    <Button type="submit" style={{ width: "100%" }}>
                      <b>Accept and Sign Up</b>
                    </Button>
                  </Col>
                </StyledRow>
              </>
            )}
          </form>
        </FormProvider>
        {step === "confirmation-code" && (
          <FormProvider {...confirmationFormMethods}>
            <form onSubmit={confirmationFormMethods.handleSubmit(confirmSignUp)}>
              <>
                <Row justifyContent="center">
                  <Col lg={4} md={6} sm={8} xs={10}>
                    <ContextTextField label="Email address" type="text" name="email" placeholder="Email" disabled />
                  </Col>
                </Row>
                <Row justifyContent="center">
                  <Col lg={4} md={6} sm={8} xs={10}>
                    <ContextTextField
                      label="Confirmation code"
                      type="text"
                      name="confirmationCode"
                      placeholder="Confirmation code"
                    />
                  </Col>
                </Row>
                <StyledRow justifyContent="center">
                  <StyledColumn lg={2} md={3} sm={4} xs={6}>
                    <ResendButton onClick={(e) => handleResendCode(e)} disabled={disabled}>
                      {!disabled && (
                        <ResendText>
                          <b>Resend Code</b>
                          <IconWrapper>
                            <StyledIcon icon={faArrowRotateLeft} size="1x" />
                          </IconWrapper>
                        </ResendText>
                      )}
                      {runTimer && (
                        <div>
                          {minutes}:{seconds}
                        </div>
                      )}
                    </ResendButton>
                  </StyledColumn>
                  <StyledColumn lg={2} md={3} sm={4} xs={6}>
                    <Button type="submit">
                      <b>Submit</b>
                      <IconWrapper>
                        <StyledIcon icon={faChevronRight} size="1x" />
                      </IconWrapper>
                    </Button>
                  </StyledColumn>
                </StyledRow>
              </>
            </form>
          </FormProvider>
        )}
        {step === "signup-success" && (
          <LoginLink>
            Account is created successfully, click <Link to="/login">here</Link> to login
          </LoginLink>
        )}
      </Container>
    </AuthLayout>
  );
};

export default SignUp;
