import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ModalVideo from "react-modal-video";

const StyledLink = styled.a`
  text-decoration: underline;
  color: #111111;
`;

const VideoPlayButton = ({ videoUrl }) => {
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      const collection = document.getElementsByClassName("modal-video-close-btn");
      if (collection[0]) {
        collection[0].onclick = (event) => {
          setOpen(false);
        };
      }
    }
  }, [setOpen, isOpen]);

  return (
    <>
      <ModalVideo isOpen={isOpen} channel="custom" url={videoUrl}>
        <video autoPlay controls name="media" width="100%" onContextMenu={(e) => e.preventDefault()}>
          <source src={videoUrl} type="video/mp4" />
        </video>
      </ModalVideo>
      <StyledLink onClick={() => setOpen(true)} href="#videos">
        View
      </StyledLink>
    </>
  );
};

export default VideoPlayButton;
