import React from "react";
import styled from "styled-components";

const StyledInput = styled.input`
  display: block;
  box-sizing: border-box;
  height: 58px;
  padding: 16px 20px;
  border-radius: 8px;
  border: none;
  outline: none;
  width: 100%;

  font-size: 16px;
  letter-spacing: 0;
  line-height: 25px;

  &::placeholder {
    color: #7d858a;
    font-style: italic;
    font-size: 16px;
  }

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #ffffff inset;
  }

  :disabled {
    background: #eaeaea;
  }
`;

const Input = ({ value = "", onChange, type = "text", placeholder, isDisabled }) => {
  return (
    <StyledInput
      value={value}
      onChange={(event) => onChange(event.target.value)}
      placeholder={placeholder}
      type={type}
      disabled={isDisabled}
    />
  );
};

export default Input;
