import React from "react";
import styled from "styled-components";
import { media } from "styled-bootstrap-grid";

const BaseButton = styled.button`
  background-color: ${(props) => (props.inverted ? "transparent" : "#fec00f")};
  border-radius: 35px;
  border: ${(props) => (props.inverted ? "1px solid #fbba18" : "0")};

  color: ${(props) => (props.inverted ? "#000005" : "#3e3e3e")};
  font-size: 14px;
  font-weight: 700;

  min-height: 50px;
  padding: 15px 20px;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  :hover {
    background-color: #fec932;
    border-color: #fec932;
  }
  :active {
    background-color: #feb30b;
    border-color: #feb30b;
  }

  ${media.lg`
    padding: 15px 35px;
  `}
`;

const Button = ({ children, ...rest }) => {
  return <BaseButton {...rest}>{children}</BaseButton>;
};

export default Button;
