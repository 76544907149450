import React, { useState, useEffect } from "react";
import { Container, Row, Col, media } from "styled-bootstrap-grid";
import axios from "axios";
import styled from "styled-components";
import Layout from "../components/Layout";
import Title from "../components/Title";
import VideoGroups from "../components/VideoGroups";
import PageHeader from "../components/PageHeader";

const MembersContainer = styled(Container)`
  margin-top: 150px;

  ${media.lg`
    margin-top:300px;
  `}
`;

const Training = ({ jwtToken }) => {
  const [resources, setResources] = useState(null);
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const queryResult = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/trainingResources`, {
          headers: {
            Authorization: jwtToken,
          },
        });

        setResources(queryResult.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchProduct();
  }, [jwtToken]);

  return (
    <Layout>
      <PageHeader>
        <Container>
          <Row alignItems="center">
            <Col col={12}>
              <Title>Training</Title>
            </Col>
          </Row>
        </Container>
      </PageHeader>
      <MembersContainer>
        {resources && resources.map((videoGroup, index) => <VideoGroups videoData={videoGroup} key={index} />)}
      </MembersContainer>
    </Layout>
  );
};

export default Training;
