import React from "react";
import { navigate } from "@reach/router";
import { useAlert } from "react-alert";
import _ from "lodash";
import Auth from "@aws-amplify/auth";

const isAllowed = (userGroups, grantedGroups) => _.intersection(userGroups, grantedGroups).length > 0;

const isSuperAdminGroup = (userGroups) => isAllowed(userGroups, ["SSCGGroup", "InnoflexAdminGroup"]);

const canManageUsers = (userGroups) => isAllowed(userGroups, ["SWAdminGroup"]);

const canManageMembers = (userGroups, dataGroups) =>
  isSuperAdminGroup(userGroups) || (canManageUsers(userGroups) && !isSuperAdminGroup(dataGroups));

const canViewProductResources = (userGroups) =>
  isAllowed(userGroups, [
    "SSCGGroup",
    "DistributorTeamMemberGroup",
    "ManufacturerGroup",
    "SilverDealerGroup",
    "GoldDealerGroup",
    "PlatinumDealerGroup",
    "InternationalDealerGroup",
    "InnoflexAdminGroup",
    "SWAdminGroup",
  ]);

const canViewProductPrice = (userGroups) =>
  isAllowed(userGroups, [
    "SSCGGroup",
    "DistributorTeamMemberGroup",
    "ManufacturerGroup",
    "SilverDealerGroup",
    "GoldDealerGroup",
    "PlatinumDealerGroup",
    "InnoflexAdminGroup",
    "SWAdminGroup",
  ]);

const canAccessApplication = (userGroups) =>
  isAllowed(userGroups, [
    "SSCGGroup",
    "DistributorTeamMemberGroup",
    "ManufacturerGroup",
    "SilverDealerGroup",
    "GoldDealerGroup",
    "PlatinumDealerGroup",
    "InternationalDealerGroup",
    "InnoflexAdminGroup",
    "SWAdminGroup",
  ]);

const canExportProductsInformation = (userGroups) =>
  isAllowed(userGroups, ["SSCGGroup", "DistributorTeamMemberGroup", "InnoflexAdminGroup", "SWAdminGroup"]);

const ValidateElement = ({ children, actualFunction, component: Component }) => {
  const alert = useAlert();
  const ValidateUser = async () => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser({ bypassCache: true });

      if (!parseInt(currentUser.attributes[`custom:is_admin_invalidated`])) {
        actualFunction();
      } else {
        alert.error("Your password is expired.");
        await Auth.signOut();
        navigate("/login");
      }
    } catch (error) {
      alert.error("Something went wrong. Please try again later.");
    }
  };

  return <Component onClick={() => ValidateUser()} children={children} />;
};

export {
  canManageMembers,
  canViewProductResources,
  canAccessApplication,
  canExportProductsInformation,
  canViewProductPrice,
  isSuperAdminGroup,
  ValidateElement,
};
