import React from "react";
import styled from "styled-components";
import { Link } from "@reach/router";
import { Container, Row, Col } from "styled-bootstrap-grid";

import SpitwaterLogo from "../images/SPITWATER_CMYK_Logo_Corporate_Negative.png";

const AuthContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #111111;
  justify-content: center;
  color: #ffffff;
`;

const ImageContainer = styled.div`
  width: 100%;
  margin-top: 100px;
  margin-bottom: 120px;

  img {
    width: 100%;
  }
`;

const DealerPortalLabel = styled.h2`
  font-size: 22px;
  color: #ffffff;
  margin-bottom: 50px;
`;

const AuthLayout = ({ children }) => {
  return (
    <AuthContainer>
      <Container>
        <Row justifyContent="center">
          <Col lg={4} md={6} sm={8} xs={10}>
            <Link to="/">
              <ImageContainer>
                <img src={SpitwaterLogo} alt="logo" />
              </ImageContainer>
            </Link>
          </Col>
        </Row>
        <Row justifyContent="center">
          <Col lg={4} md={6} sm={8} xs={10}>
            <DealerPortalLabel>Dealer portal</DealerPortalLabel>
          </Col>
        </Row>
      </Container>
      {children}
    </AuthContainer>
  );
};

export default AuthLayout;
