import React from "react";
import styled from "styled-components";
import { useFormContext, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import TextField from "./TextField";

const ErrorMessageContainer = styled.div`
  box-sizing: border-box;
  min-height: 35px;
  display: flex;
  align-items: center;
`;

const RedText = styled.p`
  color: rgb(218, 97, 97);
  font-size: 14px;
`;

const ContextTextField = ({ name, placeholder, defaultValue = "", type, label, disabled = false }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={(controllerProps) => (
        <>
          <TextField
            label={label}
            name={name}
            value={controllerProps.field.value}
            onChange={controllerProps.field.onChange}
            type={type}
            placeholder={placeholder}
            disabled={disabled}
          />
          <ErrorMessageContainer>
            <ErrorMessage
              errors={errors}
              name={name}
              render={({ message }) => <RedText>{message.replace(name, label)}</RedText>}
            />
          </ErrorMessageContainer>
        </>
      )}
    />
  );
};

export default ContextTextField;
