import React, { useState, useEffect, useMemo, useContext, createContext } from "react";
import axios from "axios";
import _ from "lodash";
import { useAlert } from "react-alert";
import Modal from "react-modal";
import styled from "styled-components";
import { useTable, useSortBy } from "react-table";
import Dropdown from "react-bootstrap/Dropdown";
import { Container, Col, Row, media } from "styled-bootstrap-grid";
import { Link } from "@reach/router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faCog, faTimes, faSort, faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import Button from "../components/Button";
import Title from "../components/Title";
import Layout from "../components/Layout";
import PageHeader from "../components/PageHeader";
import TextField from "../components/TextField";
import { canManageMembers, isSuperAdminGroup, ValidateElement } from "../utils";
import { UserContext } from "../App";

const MembersContainer = styled(Container)`
  margin-top: 150px;

  ${media.lg`
    margin-top:300px;
  `}
`;

const Table = styled.table`
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
`;

const TableHeader = styled.thead`
  border-bottom: 2px solid #111111;
`;

const StyledTh = styled.th`
  padding: 20px 0;
  border: none;
  box-sizing: border-box;
  font-weight: 400;
  position: relative;
  text-align: left;
  vertical-align: top;
  width: 40%;
  ${media.lg`
    width: 30%;
  `}

  :nth-child(2) {
    width: 30%;
    ${media.lg`
      width: 20%;
    `}
  }

  :nth-child(3) {
    width: 15%;
    ${media.lg`
      width: 10%;
    `}
  }

  :nth-child(4) {
    width: 15%;
    ${media.lg`
      width: 40%;
    `}
  }
`;

const TableBodyCell = styled.td`
  padding: 40px 0;
  font-size: 16px;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  border: none;
`;

const ActionTableBodyCell = styled(TableBodyCell)`
  overflow: visible;
`;

const StyledButton = styled(Button)`
  padding: 5px 20px;
`;

const ActionButton = styled(Button)`
  min-height: 0;
  padding: 10px 20px;
  margin: 5px;
`;

const StyledLink = styled(Link)`
  width: 100%;
  text-decoration: none;
  margin: 0 15px;
`;

const ActionLink = styled(Link)`
  text-decoration: none;
`;

const ButtonCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;

const LargeScreenText = styled.span`
  display: none;
  ${media.lg`
    display: inline-block;
  `}
`;

const MobileText = styled.span`
  display: inline-block;
  ${media.lg`
    display: none;
  `}
`;

const StyledIcon = styled(FontAwesomeIcon)`
  margin-left: 10px;
`;

const ActionIcon = styled(FontAwesomeIcon)``;

const MobileDropdown = styled.div`
  display: block;
  margin-left: 10px;

  ${media.lg`
    display: none;
  `};

  .dropdown-toggle {
    background-color: #fec00f !important;
    color: #000005 !important;
    border-radius: 20px;
    border: 0;

    ::after {
      display: none;
    }

    :focus {
      box-shadow: none !important;
    }
    :active {
      color: #000005;
      background-color: #feb30b !important;
    }
  }
  .dropdown-menu {
    min-width: 0;
  }
`;

const BoldText = styled.span`
  font-weight: bold;
`;

const ItalicText = styled.span`
  font-style: italic;
`;

const ActionContainer = styled.div`
  display: none;
  ${media.lg`
    display: flex;
  `}
`;

const ContentContainer = styled.div`
  width: 100%;
`;

const ConfirmText = styled.p`
  margin: 30px 0;
`;

const CloseIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 10px;
  right: 10px;
  color: #000005;
  z-index: 1001;
`;

const ConfirmButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const EmailText = styled.span`
  font-weight: 700;
`;

const MessageContainer = styled.div`
  margin: 50px 0;
`;

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    width: "300px",
    height: "250px",
    transform: "translate(-50%, -50%)",
    color: "#111111",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
  },
};

export const MemberAction = ({ row, userGroups, onClickRevoke, onClickReset, alert }) => (
  <>
    <ActionContainer>
      {canManageMembers(userGroups, [row.original.group + "Group"]) && (
        <ValidateElement component={ActionButton} actualFunction={() => onClickRevoke(row)}>
          Revoke
        </ValidateElement>
      )}

      {!row.cells.find((cell) => cell.column.Header === "Status").value && (
        <>
          <ActionLink
            to={"/members/invite?email=" + row.cells.find((cell) => cell.column.Header === "Email").value.toLowerCase()}
          >
            <ActionButton>Re-invite</ActionButton>
          </ActionLink>
          <ActionButton onClick={() => copyToClipboard(row.original.id, alert)}>Copy Link</ActionButton>
        </>
      )}
      {row.cells.find((cell) => cell.column.Header === "Status").value &&
        canManageMembers(userGroups, [row.original.group + "Group"]) && (
          <>
            <ActionLink
              to={
                "/members/edit-role?email=" +
                row.cells.find((cell) => cell.column.Header === "Email").value.toLowerCase() +
                "&role=" +
                row.cells.find((cell) => cell.column.Header === "Group").value
              }
            >
              <ActionButton>Edit role</ActionButton>
            </ActionLink>
            <ValidateElement component={ActionButton} actualFunction={() => onClickReset(row)}>
              Reset password
            </ValidateElement>
          </>
        )}
    </ActionContainer>
    <MobileDropdown>
      <Dropdown>
        {(!row.cells.find((cell) => cell.column.Header === "Status").value ||
          canManageMembers(userGroups, [row.original.group + "Group"])) && (
          <Dropdown.Toggle className="dropdown-button">
            <ActionIcon size="sm" icon={faCog} />
          </Dropdown.Toggle>
        )}

        <Dropdown.Menu>
          {canManageMembers(userGroups, [row.original.group + "Group"]) && (
            <ValidateElement component={Dropdown.Item} onClick={() => onClickRevoke(row)}>
              Revoke
            </ValidateElement>
          )}
          {!row.cells.find((cell) => cell.column.Header === "Status").value && (
            <>
              <Dropdown.Item
                href={
                  "/members/invite?email=" +
                  row.cells.find((cell) => cell.column.Header === "Email").value.toLowerCase()
                }
              >
                Re-invite
              </Dropdown.Item>
              <ValidateElement component={Dropdown.Item} actualFunction={() => copyToClipboard(row.original.id, alert)}>
                Copy Link
              </ValidateElement>
            </>
          )}
          {row.cells.find((cell) => cell.column.Header === "Status").value &&
            canManageMembers(userGroups, [row.original.group + "Group"]) && (
              <>
                <Dropdown.Item
                  href={
                    "/members/edit-role?email=" +
                    row.cells.find((cell) => cell.column.Header === "Email").value.toLowerCase() +
                    "&role=" +
                    row.cells.find((cell) => cell.column.Header === "Group").value
                  }
                >
                  Edit role
                </Dropdown.Item>
                <ValidateElement component={Dropdown.Item} actualFunction={() => onClickReset(row)}>
                  Reset password
                </ValidateElement>
              </>
            )}
        </Dropdown.Menu>
      </Dropdown>
    </MobileDropdown>
  </>
);

export const copyToClipboard = (invitationId, alert) => {
  if (invitationId) {
    navigator.clipboard.writeText(`${window.location.origin}/signup?code=${invitationId}`);
    alert.success("Copied to clipboard");
  } else {
    alert.error("Invitation link is not available");
  }
};

const Members = ({ jwtToken }) => {
  const [users, setUsers] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openResetModal, setOpenResetModal] = useState(false);
  const [currentRow, setCurrentRow] = useState();
  const { authState, setAuthState } = useContext(UserContext);
  const [filter, setFilter] = useState("");
  const alert = useAlert();

  const columns = useMemo(
    () => [
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Group",
        accessor: "group",
      },
      {
        Header: "Status",
        accessor: "accepted",
        sortType: (a, b, id) => {
          if (a.original[id] > b.original[id]) return -1;
          if (b.original[id] > a.original[id]) return 1;
        },
      },
    ],
    []
  );

  const filteredUsers = useMemo(
    () => (filter !== "" ? users.filter((user) => user.email.toLowerCase().includes(filter.toLowerCase())) : users),
    [filter, users]
  );

  const userTableInstance = useTable(
    {
      columns,
      data: filteredUsers,
    },
    useSortBy
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = userTableInstance;

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const queryResult = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/members`, {
          headers: {
            Authorization: jwtToken,
          },
        });

        const allUsers = queryResult.data.allUsers.map((user) => ({ ...user, email: _.capitalize(user.email) }));

        setUsers(allUsers);
        setAuthState({ ...authState, users: allUsers });
      } catch (error) {
        console.error(error);
      }
    };

    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteAccessAndInvitation = async () => {
    const deletingEmail = currentRow.cells.find((cell) => cell.column.Header === "Email").value;

    let endpointUrl;

    if (!currentRow.cells.find((cell) => cell.column.Header === "Status").value) {
      endpointUrl = `${process.env.REACT_APP_API_ENDPOINT}/deleteInvitation`;
    } else {
      endpointUrl = `${process.env.REACT_APP_API_ENDPOINT}/deleteMember`;
    }

    const config = {
      method: "post",
      url: endpointUrl,
      headers: {
        Authorization: jwtToken,
        "Content-Type": "application/json",
      },
      data: { email: deletingEmail.toLowerCase() },
    };

    await axios(config)
      .then((response) => {
        setOpenModal(false);
        setUsers(users.filter((user) => user.email.toLowerCase() !== response.data));
      })
      .catch((error) => {
        if (error.response.status === 403) {
          alert.error("Forbidden to access this resources.");
        } else {
          alert.error("Something went wrong. Please try again later.");
        }
      });
  };

  const invalidateUser = async (email) => {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_ENDPOINT}/invalidateUser`,
      headers: {
        Authorization: jwtToken,
        "Content-Type": "application/json",
      },
      data: {
        email: email,
      },
    };
    await axios(config)
      .then((response) => {
        setOpenResetModal(false);
      })
      .catch((error) => {
        if (error.response.status === 403) {
          alert.error("Forbidden to access this resources.");
        } else {
          alert.error("Cannot reset member");
        }
      });
  };

  const onClickRevoke = (data) => {
    setOpenModal(true);
    setCurrentRow(data);
  };

  const onClickReset = (data) => {
    setOpenResetModal(true);
    setCurrentRow(data);
  };

  const onCloseModal = () => {
    setOpenModal(false);
    setOpenResetModal(false);
  };

  const onChangeFilter = (e) => {
    e.preventDefault();
    setFilter(e.target.value);
  };

  return (
    <Layout>
      <PageHeader>
        <Container>
          <Row justifyContent="between" alignItems="center">
            <Col col={5}>
              <Title>Members</Title>
            </Col>
            <Col col={4}>
              <TextField label="" placeholder="Search member" onChange={(e) => onChangeFilter(e)} />
            </Col>
            <ButtonCol col={3}>
              <StyledLink to="/members/invite">
                <StyledButton>
                  <LargeScreenText>Invite a new member</LargeScreenText>
                  <MobileText>Invite</MobileText>
                  <StyledIcon size="sm" icon={faPlus} />
                </StyledButton>
              </StyledLink>
            </ButtonCol>
          </Row>
        </Container>
      </PageHeader>

      <MembersContainer>
        {!filteredUsers.length && (
          <Row justifyContent="center">
            <MessageContainer>
              <p>
                <i>No content available that matches your filters.</i>
              </p>
            </MessageContainer>
          </Row>
        )}
        {!!filteredUsers.length && (
          <Row>
            <Col col={12}>
              <Table {...getTableProps()}>
                <TableHeader>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <StyledTh {...column.getHeaderProps(column.getSortByToggleProps())}>
                          {column.render("Header")}

                          <span>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <StyledIcon size="sm" icon={faSortDown} />
                              ) : (
                                <StyledIcon size="sm" icon={faSortUp} />
                              )
                            ) : (
                              <StyledIcon size="sm" icon={faSort} />
                            )}
                          </span>
                        </StyledTh>
                      ))}
                      <StyledTh>Action</StyledTh>
                    </tr>
                  ))}
                </TableHeader>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          if (cell.column.Header === "Group") {
                            return <TableBodyCell {...cell.getCellProps()}>{_.startCase(cell.value)}</TableBodyCell>;
                          }

                          if (cell.column.Header === "Status") {
                            return (
                              <TableBodyCell {...cell.getCellProps()}>
                                {cell.value ? <BoldText>Active</BoldText> : <ItalicText>Pending</ItalicText>}
                              </TableBodyCell>
                            );
                          }

                          return <TableBodyCell {...cell.getCellProps()}>{cell.render("Cell")}</TableBodyCell>;
                        })}
                        <ActionTableBodyCell>
                          <MemberAction
                            row={row}
                            userGroups={authState.userGroups}
                            onClickRevoke={onClickRevoke}
                            onClickReset={onClickReset}
                            alert={alert}
                          />
                        </ActionTableBodyCell>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>
        )}
      </MembersContainer>
      <Modal isOpen={openModal} onRequestClose={onCloseModal} style={modalStyles} ariaHideApp={false}>
        <ContentContainer>
          <CloseIcon onClick={onCloseModal} icon={faTimes} size="1x" />
          {currentRow?.cells.find((cell) => cell.column.Header === "Status").value && (
            <ConfirmText>
              Are you sure want to delete user{" "}
              <EmailText>{currentRow?.cells.find((cell) => cell.column.Header === "Email").value}</EmailText> from the
              system?
            </ConfirmText>
          )}
          {!currentRow?.cells.find((cell) => cell.column.Header === "Status").value && (
            <ConfirmText>
              Are you sure you want to revoke invitation to{" "}
              <EmailText>{currentRow?.cells.find((cell) => cell.column.Header === "Email").value}</EmailText>?
            </ConfirmText>
          )}

          <ConfirmButtonContainer>
            <ActionButton onClick={() => deleteAccessAndInvitation()}>Yes</ActionButton>
            <ActionButton onClick={() => setOpenModal(false)}>No</ActionButton>
          </ConfirmButtonContainer>
        </ContentContainer>
      </Modal>
      <Modal isOpen={openResetModal} onRequestClose={onCloseModal} style={modalStyles} ariaHideApp={false}>
        <ContentContainer>
          <CloseIcon onClick={onCloseModal} icon={faTimes} size="1x" />
          {currentRow?.cells.find((cell) => cell.column.Header === "Status").value && (
            <ConfirmText>
              Are you sure want to expire user{" "}
              <EmailText>{currentRow?.cells.find((cell) => cell.column.Header === "Email").value}</EmailText> password?
            </ConfirmText>
          )}

          <ConfirmButtonContainer>
            <ActionButton
              onClick={() => invalidateUser(currentRow?.cells.find((cell) => cell.column.Header === "Email").value)}
            >
              Yes
            </ActionButton>
            <ActionButton onClick={() => setOpenResetModal(false)}>No</ActionButton>
          </ConfirmButtonContainer>
        </ContentContainer>
      </Modal>
    </Layout>
  );
};

export default Members;
