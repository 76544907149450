import React, { useState } from "react";
import styled from "styled-components";
import _ from "lodash";
import { Container, Row, Col, media } from "styled-bootstrap-grid";

const SummarySection = styled.section`
  padding: 40px 0;
  margin-top: 150px;

  ${media.lg`
    margin-top: 300px;
  `}
`;

const LargeImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #d8d8d8;
  margin-bottom: 30px;

  cursor: pointer;

  img {
    width: 100%;
  }
`;

const ImageList = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImageItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #d8d8d8;
  margin-bottom: 10px;

  cursor: pointer;

  img {
    width: 100%;
  }
`;

const GeneralInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const GeneralLabel = styled.h2`
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 20px;
`;

const StyledHr = styled.hr`
  margin: 0;
  border-top: 1px dashed #d8d8d8;
`;

const DetailGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
  min-height: 60px;
`;

const DetailName = styled.span`
  font-size: 18px;
`;

const DetailValue = styled.span`
  font-size: 24px;
`;

const ProductSummary = ({ productImageUrls, code, name, heroImageUrl }) => {
  const [imageIndex, setImageIndex] = useState(0);

  const sortedProductImageUrls =
    productImageUrls[0] !== heroImageUrl
      ? _.sortBy(productImageUrls, (url) => (url === heroImageUrl ? 0 : 1))
      : productImageUrls;

  return (
    <SummarySection>
      <Container>
        <Row>
          <Col col={9} lg={5}>
            <LargeImageContainer>
              <img src={sortedProductImageUrls[imageIndex]} alt="main" />
            </LargeImageContainer>
          </Col>
          <Col col={3} lg={1}>
            <ImageList>
              {sortedProductImageUrls.map((url, index) => (
                <ImageItem key={String(index)} onClick={() => setImageIndex(index)}>
                  <img src={url} alt={"sub" + index} />
                </ImageItem>
              ))}
            </ImageList>
          </Col>
          <Col col={12} lg={6}>
            <GeneralInformationContainer>
              <GeneralLabel>General Details</GeneralLabel>
              <StyledHr />
              <DetailGroup>
                <DetailName>Model Name</DetailName>
                <DetailValue>{name}</DetailValue>
              </DetailGroup>
              <StyledHr />
              <DetailGroup>
                <DetailName>SKU</DetailName>
                <DetailValue>{code}</DetailValue>
              </DetailGroup>
              <StyledHr />
            </GeneralInformationContainer>
          </Col>
        </Row>
      </Container>
    </SummarySection>
  );
};

export default ProductSummary;
