import React from "react";
import styled from "styled-components";
import { useFormContext, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Select from "./Select";
import FieldMessage from "./FieldMessage";
import FieldContainer from "./FieldContainer";
import Label from "./Label";

const ControlContainer = styled.div`
  width: 100%;
`;

const SelectContainer = styled.div`
  margin-top: 30px;
`;

const ContextSelect = ({ name, label, defaultValue = "", options, placeholder }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <FieldContainer>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { ref, ...rest } }) => (
          <ControlContainer>
            <Label>{label}</Label>
            <SelectContainer>
              <Select
                name={name}
                placeholder={placeholder}
                options={options}
                {...rest}
                value={options.find((opt) => opt.value === rest.value) || ""}
                onChange={(selected) => (selected ? rest.onChange(selected.value) : rest.onChange(""))}
                isSearchable={true}
              />
            </SelectContainer>
          </ControlContainer>
        )}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <FieldMessage>{message.replace(name, label).replace("*", "")}</FieldMessage>}
      />
    </FieldContainer>
  );
};

export default ContextSelect;
