import React, { useState, useContext } from "react";
import styled from "styled-components";
import axios from "axios";
import Auth from "@aws-amplify/auth";
import { Link, navigate } from "@reach/router";
import { media } from "styled-bootstrap-grid";
import { useAlert } from "react-alert";
import SpitwaterLogo from "../images/SPITWATER_CMYK_Logo_Corporate_Negative.png";
import HamburgerButton from "./HamburgerButton";
import { MobileMenuContext } from "./Layout";
import { UserContext } from "../App";
import { canManageMembers, canExportProductsInformation } from "../utils";

const NavContainer = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  width: 100%;
  padding: 0 20px;
  background-color: #000000;

  position: fixed;
  z-index: 10;
  top: 0;

  img {
    width: 150px;
  }

  ${media.lg`
    padding: 0 50px;
    height: 160px;
    img {
      width: 252px;
      height: 65px;
    }
  `}
`;

const NavigationList = styled.ul`
  list-style: none;
  text-align: right;
  padding-left: 0px;
`;

const NavigationItem = styled.li`
  display: none;
  ${media.lg`
    display: inline-block;
  `}
`;

const MobileNavigationItem = styled.li`
  display: inline-block;
  ${media.lg`
    display: none;
  `}
`;

const BaseLink = styled(Link)`
  color: #fefefe;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  text-decoration: none;
  margin-right: 20px;

  ${media.md`
    margin-right: 50px;
  `}
`;

const BaseButton = styled.div`
  color: ${(props) => (props.downloading === "true" ? "#0a58ca" : "#fefefe")};
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  text-decoration: none;
  margin-right: 20px;
  cursor: pointer;

  :hover {
    color: #0a58ca;
  }

  ${media.md`
    margin-right: 50px;
  `}
`;

const StyledExternalLink = styled.a`
  color: #fefefe;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  text-decoration: none;
  margin-right: 20px;

  ${media.md`
    margin-right: 50px;
  `}
`;

const NavigationBar = () => {
  const { mobileMenu, setMobileMenu } = useContext(MobileMenuContext);
  const [downloading, setDownloading] = useState(false);
  const { authState } = useContext(UserContext);
  const alert = useAlert();

  const logout = async () => {
    await Auth.signOut();
    navigate("/login");
  };

  const exportProducts = async () => {
    setDownloading(true);
    try {
      const queryResult = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/export`, {
        headers: {
          Authorization: authState.jwtToken,
        },
      });
      if (queryResult) {
        window.location.assign(queryResult.data);
      }
    } catch (error) {
      if (error.response.status === 403) {
        alert.error("Forbidden to access this resources.");
      }
    }

    setDownloading(false);
  };

  return (
    <NavContainer>
      <Link to="/dealer/products">
        <img src={SpitwaterLogo} alt="logo" />
      </Link>
      <NavigationList>
        <NavigationItem>
          <BaseLink to="/dealer/products">Products</BaseLink>
        </NavigationItem>
        <NavigationItem>
          <BaseLink to="/training">Training</BaseLink>
        </NavigationItem>
        {canManageMembers(authState.userGroups) && (
          <NavigationItem>
            <BaseLink to="/members">Members</BaseLink>
          </NavigationItem>
        )}
        {canExportProductsInformation(authState.userGroups) && (
          <NavigationItem>
            <BaseButton onClick={() => exportProducts()} downloading={downloading.toString()}>
              Export
            </BaseButton>
          </NavigationItem>
        )}
        <NavigationItem>
          <StyledExternalLink href={process.env.REACT_APP_WEBSITE_LINK} target="blank">
            Website
          </StyledExternalLink>
        </NavigationItem>
        <NavigationItem>
          <StyledExternalLink onClick={logout}>Logout</StyledExternalLink>
        </NavigationItem>
        <MobileNavigationItem>
          <HamburgerButton showMenu={mobileMenu} setShowMenu={setMobileMenu} />
        </MobileNavigationItem>
      </NavigationList>
    </NavContainer>
  );
};

export default NavigationBar;
