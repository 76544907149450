import styled from "styled-components";

const FieldMessage = styled.p`
  margin-top: 10px;
  height: 40px;
  color: #ff0000;
  letter-spacing: 0;
`;

export default FieldMessage;
