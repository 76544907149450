import React, { useState, useEffect } from "react";
import _ from "lodash";
import styled from "styled-components";
import { Link, navigate } from "@reach/router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col } from "styled-bootstrap-grid";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Auth from "@aws-amplify/auth";
import Amplify from "@aws-amplify/core";
import AuthLayout from "../components/AuthLayout";
import Button from "../components/Button";
import ContextTextField from "../components/ContextTextField";
import { canAccessApplication } from "../utils";

const awsconfig = {
  aws_project_region: "ap-southeast-2",
  aws_cognito_region: "ap-southeast-2",
  aws_user_pools_id: process.env.REACT_APP_COGNITO_POOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_COGNITO_CLIENT_ID,
  oauth: {},
};

Amplify.configure({
  ...awsconfig,
});

const StyledRow = styled(Row)`
  margin-top: 20px;
  margin-bottom: 40px;
  padding-left: 40px;
  padding-right: 40px;
`;

const ForgotPasswordLabel = styled(Link)`
  font-size: 16px;
  color: #ffffff;
  text-decoration: none;
  :hover {
    color: #fbba18;
  }
`;

const BackToWebsite = styled.a`
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  :hover {
    color: #fbba18;
  }
`;

const IconWrapper = styled.span`
  margin-left: 10px;
`;

const StyledIcon = styled(FontAwesomeIcon)`
  font-size: 30px;
`;

const StyledColumn = styled(Col)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const signInSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

const updatePasswordSchema = yup.object().shape({
  newPassword: yup.string().required(),
});

const forceResetPasswordSchema = yup.object().shape({
  oldPassword: yup.string().required(),
  newPassword: yup.string().required(),
  confirmPassword: yup.string().required(),
});

const Login = () => {
  const [userUpdatePassword, setUserUpdatePassword] = useState({ should: false, cognitoUser: null });
  const [forceUpdatePassword, setForceUpdatePassword] = useState({ should: false, cognitoUser: null });

  const signIn = async (data) => {
    try {
      const authenticationResult = await Auth.signIn(data.email, data.password);

      const diffTime = Math.abs(new Date() - new Date(authenticationResult.attributes[`custom:password_updated_at`]));
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays > 90 || !!parseInt(authenticationResult.attributes[`custom:is_admin_invalidated`])) {
        setForceUpdatePassword({
          should: true,
          cognitoUser: authenticationResult,
        });
      } else {
        if (!authenticationResult.challengeName) {
          navigate("/dealer/products");
        }
        if (authenticationResult.challengeName === "NEW_PASSWORD_REQUIRED") {
          setUserUpdatePassword({
            should: true,
            cognitoUser: authenticationResult,
          });
        }
      }
    } catch (error) {
      signInFormMethods.setError("password", { type: "validate", message: error.message });
    }
  };

  const updatePassword = async (data) => {
    const { cognitoUser } = userUpdatePassword;
    const { newPassword } = data;

    try {
      const updatePasswordResult = await Auth.completeNewPassword(cognitoUser, newPassword);
      await Auth.updateUserAttributes(cognitoUser, {
        "custom:password_updated_at": new Date().toISOString(),
      });
      const jwtPayload = _.get(updatePasswordResult, "signInUserSession.idToken.payload", { "cognito:groups": [] });
      const userGroups = jwtPayload["cognito:groups"] ?? [];

      if (!!canAccessApplication(userGroups)) {
        navigate("/dealer/products");
      } else {
        updatePasswordFormMethods.setError("newPassword", {
          type: "validate",
          message: "You are not allowed to access this site.",
        });
      }
    } catch (error) {
      updatePasswordFormMethods.setError("newPassword", { type: "validate", message: error.message });
    }
  };

  const forceResetPassword = async (data) => {
    const { cognitoUser } = forceUpdatePassword;
    if (data.newPassword !== data.confirmPassword) {
      forceResetPasswordFormMethods.setError("confirmPassword", {
        type: "validate",
        message: "Passwords do not match",
      });
    } else {
      try {
        if (!!cognitoUser) {
          await Auth.changePassword(cognitoUser, data.oldPassword, data.newPassword);

          await Auth.updateUserAttributes(cognitoUser, {
            "custom:password_updated_at": new Date().toISOString(),
            "custom:is_admin_invalidated": "0",
          });
          await Auth.signOut();
          setForceUpdatePassword({
            should: false,
            cognitoUser: null,
          });
        }
      } catch (error) {
        forceResetPasswordFormMethods.setError("confirmPassword", { type: "validate", message: error.message });
      }
    }
  };

  useEffect(() => {
    const loadCurrentUser = async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();

        const jwtPayload = _.get(currentUser, "signInUserSession.idToken.payload", { "cognito:groups": [] });
        const userGroups = jwtPayload["cognito:groups"] ?? [];

        if (!!canAccessApplication(userGroups)) {
          navigate("/dealer/products");
        }
      } catch (error) {
        navigate("/login");
      }
    };

    loadCurrentUser();
  }, []);

  const signInFormMethods = useForm({
    resolver: yupResolver(signInSchema),
  });

  const updatePasswordFormMethods = useForm({
    resolver: yupResolver(updatePasswordSchema),
  });

  const forceResetPasswordFormMethods = useForm({
    resolver: yupResolver(forceResetPasswordSchema),
  });

  return (
    <AuthLayout>
      <Container>
        {userUpdatePassword.should && (
          <FormProvider {...updatePasswordFormMethods}>
            <form onSubmit={updatePasswordFormMethods.handleSubmit(updatePassword)}>
              <Row justifyContent="center">
                <Col lg={4} md={6} sm={8} xs={10}>
                  <ContextTextField label="New Password" type="password" name="newPassword" placeholder="Password" />
                </Col>
              </Row>
              <Row justifyContent="center">
                <Col col={4}>
                  <Button type="submit">Update password</Button>
                </Col>
              </Row>
            </form>
          </FormProvider>
        )}
        {!userUpdatePassword.should && !forceUpdatePassword.should && (
          <>
            <FormProvider {...signInFormMethods}>
              <form onSubmit={signInFormMethods.handleSubmit(signIn)}>
                <Row justifyContent="center">
                  <Col lg={4} md={6} sm={8} xs={10}>
                    <ContextTextField label="Email address" type="text" name="email" placeholder="Email" />
                  </Col>
                </Row>
                <Row justifyContent="center">
                  <Col lg={4} md={6} sm={8} xs={10}>
                    <ContextTextField label="Password" type="password" name="password" placeholder="Password" />
                  </Col>
                </Row>
                <StyledRow justifyContent="center">
                  <StyledColumn lg={4} md={6} sm={8} xs={10} noGutter>
                    <ForgotPasswordLabel to="/reset-password">Forgot Password</ForgotPasswordLabel>
                    <Button type="submit">
                      <b>Sign in</b>
                      <IconWrapper>
                        <StyledIcon icon={faChevronRight} size="1x" />
                      </IconWrapper>
                    </Button>
                  </StyledColumn>
                </StyledRow>
              </form>
            </FormProvider>
            <Row justifyContent="center">
              <Col col={4}>
                <BackToWebsite href={process.env.REACT_APP_WEBSITE_LINK}>Back to website</BackToWebsite>
              </Col>
            </Row>
          </>
        )}
        {forceUpdatePassword.should && (
          <FormProvider {...forceResetPasswordFormMethods}>
            <form onSubmit={forceResetPasswordFormMethods.handleSubmit(forceResetPassword)}>
              <Row justifyContent="center">
                <Col lg={4} md={6} sm={8} xs={10}>
                  <ContextTextField
                    label="Old Password"
                    type="password"
                    name="oldPassword"
                    placeholder="Old Password"
                  />
                </Col>
              </Row>
              <Row justifyContent="center">
                <Col lg={4} md={6} sm={8} xs={10}>
                  <ContextTextField
                    label="New Password"
                    type="password"
                    name="newPassword"
                    placeholder="New Password"
                  />
                </Col>
              </Row>
              <Row justifyContent="center">
                <Col lg={4} md={6} sm={8} xs={10}>
                  <ContextTextField
                    label="Confirm New Password"
                    type="password"
                    name="confirmPassword"
                    placeholder="Confirm New Password"
                  />
                </Col>
              </Row>
              <Row justifyContent="center">
                <Col col={4}>
                  <Button type="submit">Change Password</Button>
                </Col>
              </Row>
            </form>
          </FormProvider>
        )}
      </Container>
    </AuthLayout>
  );
};

export default Login;
