import React, { useContext } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "styled-bootstrap-grid";
import { canViewProductResources } from "../utils";
import { UserContext } from "../App";

const StyledHr = styled.hr`
  margin: 0;
  border-top: 1px dashed #d8d8d8;
`;

const TabMenuContainer = styled.nav`
  display: flex;
  flex-direction: row;
`;

const TabMenuItem = styled.a`
  font-size: 18px;
  padding-bottom: 20px;
  padding-right: 30px;
  text-decoration: none;
  color: #111111;
  font-weight: ${(props) => (props.active ? 700 : 400)};
`;

const Tabs = ({ active }) => {
  const { authState } = useContext(UserContext);

  return (
    <section>
      <Container>
        <Row>
          <Col col={12}>
            <TabMenuContainer>
              <TabMenuItem href="#technical-specifications" active={active === "#technical-specifications"}>
                Technical Specifications
              </TabMenuItem>
              {canViewProductResources(authState.userGroups) && (
                <TabMenuItem href="#documents" active={active === "#documents"}>
                  Documents
                </TabMenuItem>
              )}
              {canViewProductResources(authState.userGroups) && (
                <TabMenuItem href="#videos" active={active === "#videos"}>
                  Videos
                </TabMenuItem>
              )}
            </TabMenuContainer>
          </Col>
        </Row>
        <Row>
          <Col col={12}>
            <StyledHr />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Tabs;
